<template>
  <div class="d-flex justify-content-center flex-column align-items-center">
    <b-form @submit="searchBtn" class="col-8 clearfix">
      <h3>Mi medicamento</h3>


      <b-form-group id="input-group-2" label="Entidad:" label-for="input-2">
        <v-select v-model="form.entity" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name"
          :options="entities" />
      </b-form-group>

      <b-form-group id="input-group-2" label="Medicamento:" label-for="input-2">
        <b-form-input id="input-2" v-model="form.name" placeholder="Escriba Medicamento" @keyup.enter="searchBtn"
          required></b-form-input>
      </b-form-group>

      <b-button type="button" class="btn-sm" @click="searchBtn" variant="primary">Buscar</b-button>
      <b-button type="button" class="btn-sm ml-2" @click="form.name = ''" variant="success">Limpiar</b-button>
    </b-form>
    <div v-if="searchActive">
      <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
    </div>
    <div class="col-8 mt-2" v-if="search">
      <div v-if="cums.hits.length || cumsWithoutInventory.hits.length">
        <div v-if="cums.hits.length > 0">
          <b-alert show variant="success">
            <p class="p-1">
              Encontramos los siguientes medicamentos con disponibilidad 😃
            </p>
          </b-alert>
          <cums-stock-form :cums="cums" :client="client"></cums-stock-form>
        </div>
        <div v-if="cumsWithoutInventory.hits.length && cums.hits.length == 0">
          <b-alert show variant="warning" class="p-1">
            <p>
              No hay cantidades disponibles 😥. Sin embargo, encontramos las
              siguientes referencias sobre las cuales puedes solicitar que se te
              notifique cuando haya disponibilidad. Por favor escoge los
              medicamentos que se acomoden a tu búsqueda 😎 y te avisaremos
              cuando lleguen 📞📨😍
            </p>

          </b-alert>
          <cums-form :search="form" :notificar="true" :cums="cumsWithoutInventory"></cums-form>
          <b-alert show variant="warning" class="p-1">

            <p>
              También puedes mejorar tu búsqueda y volverla a hacer las veces
              que desees, agregando o quitando palabras como la concentración
              (ejemplo 500 mg) y la presentación (ejemplo tableta) 💡
            </p>
            <p>
              En algunas farmacias los medicamentos que necesitan autorización
              se piden bajo solicitud,, si es tu caso y si has consultado varias
              veces y tu medicamento sigue sin cantidad, te sugerimos
              comunicarte con la farmacia ✏
            </p>
          </b-alert>
        </div>
      </div>
      <div v-else>
        <b-alert show variant="warning">
          <div class="p-2">
            <p>No encontramos resultados en base a tu búsqueda 😰</p>
            <p>
              Puedes mejorar tu búsqueda y volverla a hacer las veces que
              desees, agregando o quitando palabras como la concentración
              (ejemplo 500 mg) y la presentación (ejemplo tableta) 💡
            </p>
            <p>
              En algunas farmacias los medicamentos que necesitan autorización
              se piden bajo solicitud,, si es tu caso y si has consultado varias
              veces y tu medicamento sigue sin cantidad, te sugerimos
              comunicarte con la farmacia ✏
            </p>
          </div>
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import cumsForm from "@/views/miscellaneous/cums.vue";
import cumsStockForm from "@/views/miscellaneous/cums-stok.vue";
import { useWebWorker } from "@vueuse/core";
export default {
  name: "UserForm",
  components: {
    vSelect,
    cumsForm,
    cumsStockForm,
  },
  data() {
    return {
      cities: [],
      states: [],
      customers: [],
      entities: [],
      cumDetail: {
        cum: {},
      },
      dismissSecs: 10,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      cumsWithoutInventory: { hits: [] },
      cums: {
        hits: [],
      },
      search: false,
      notification: {},
      form: {
        cityId: "",
        stateId: "",
        customer: "",
        entity: null,
      },
      searchActive: false,
      client: {}
    };
  },
  methods: {
    getEntities() {
      let user = JSON.parse(localStorage.getItem('userData'));
      this.$http.get(`/entities/selectForPharmacy/` + user.id).then((res) => {
        this.entities = res.data.entities;
      });
    },
    getCustomer() {
      this.$http
        .get(`/users/get-customers`)
        .then((res) => {
          this.form.stateId = res.data[0].state_id;
          this.form.cityId = res.data[0].city_id;
          this.form.customer = res.data[0].id;
          this.client = res.data[0];
        });
    },

    searchBtn() {
      this.searchActive = true;
      this.search = false;
      this.$http.post(`/cum/search`, this.form).then((res) => {
        this.cums = res.data.resultsWithInventory;
        this.cumsWithoutInventory = res.data.cums;
        this.search = true;
        this.searchActive = false;
      }).catch(err => {
        this.searchActive = false;
      });
    },
  },
  created() {
    this.getCustomer();
    this.getEntities();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style scoped>
.vs__selected {
  color: #000 !important;
}
</style>


